<template>
  <div class="topssbox1">
    <div class="topss">
      
      <a href="javascript:;" >
        <div class="topssbox" @click="search">
          <img src="images/s.png" />
          <input  type="text" v-model="options.searchParams.title" placeholder=""/>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { reactive,computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
     props:{
        isLink:{
            type:Boolean,
            default:false
        },
    },
    emits: ["toggleSearch"],
    setup(props,context){
        const options=reactive({
            searchParams:{
            draw: 1,
            start: 0,
            length: 10,
            title:''
        }
        });
        const store = useStore();
        const router= useRouter();
        options.searchParams=computed(() => store.state.common.searchParams)
        function search(){
            store.commit('setSearchParams',options.searchParams);
            if(props.isLink){
                 router.push({path:'/auctionList'});
            }else{
                context.emit('toggleSearch')
            }
             
        }
        return {
            options,
            search
        }
    }
  
};
</script>

<style>
.topss .topssbox input{
    float: right;
    width: 80%;
    border: 0;
    outline: none;
    height: 1.8rem;
    line-height: 1.8rem;
    color: #999;
    font-size: 0.8rem;
    background: none;
    padding-left: 0.3rem;
    position: relative;
    z-index: 200;
}
</style>