import auctionApi from "@/api/auctionApi";
import { reactive } from "@vue/reactivity";
import snapApi from "@/api/snapApi";

const auctionSearchModel=()=>{
    const optionsActions = reactive({
        auctions: [],
        loading: false,
        finished: false,
        refreshing: false,
        pageCount: 1,
        pageNo:1,
        total: 0,
        style: 1,
      });
      const params = reactive({
        draw: 1,
        pageNo: 0,
        length: 20,
        type: "DEFAULT",
        order: "a",
      });
      const auctionTypeSearch = (value) => {
        params.draw = 1;
        params.pageNo = 0;
        params.length = 20;
        if (value != "SNAP") {
          params.type = value;
          if (value == "DEFAULT") {
            optionsActions.style = 1;
          } else {
            optionsActions.style = 2;
          }
        } else {
            optionsActions.style = 3;
          params.type = "";
        }
        params.order = "a";
        onRefresh();
      };
      const onRefresh = async () => {
        optionsActions.finished = false;
        optionsActions.loading = true;
        optionsActions.refreshing = true;
        console.log("=========index start search==========");
        onLoad();
      };
      const onLoad = async () => {
        console.log("refreshing:" + optionsActions.refreshing);
        if (optionsActions.refreshing) {
            optionsActions.auctions = [];
            optionsActions.pageCount = 1;
            optionsActions.pageNo=1;
            optionsActions.refreshing = false;
        }else{
            if(optionsActions.pageNo<optionsActions.pageCount){
                optionsActions.pageNo=optionsActions.pageNo+1;
          }
        }
       
        params.pageNo = (optionsActions.pageNo - 1) 
        console.log(optionsActions.auctions.length + ":" + optionsActions.pageNo+":"+optionsActions.pageCount+":"+optionsActions.loading);
        if (
            optionsActions.auctions.length > 0 &&
            optionsActions.total > 0 &&
            optionsActions.auctions.length >= optionsActions.total
        ) {
            optionsActions.finished = true;
            optionsActions.loading = false;
          return;
        }
        optionsActions.loading=true;
        if (optionsActions.style < 3) {
          await auctionApi.search(params).then((res) => {
            if (res.status == 200) {
                optionsActions.total = res.recordsTotal;
             
              let count = Math.ceil(optionsActions.total / params.length);
              optionsActions.pageCount = count;
              res.data.forEach((item) => {
                if (optionsActions.auctions.length < res.recordsTotal) {
                    optionsActions.auctions.push(item);
                }
              });
              optionsActions.loading = false;
              if (optionsActions.auctions.length >= res.recordsTotal) {
                optionsActions.finished = true;
              }
            } else {
                optionsActions.finished = true;
            } 
          });
        } else {
          params.order = "";
          await snapApi.search(params).then((res) => {
            if (res.status == 200) {
                optionsActions.total = res.recordsTotal;
              let count = Math.ceil(res.recordsTotal / params.length);
              optionsActions.pageCount = count;
              res.data.forEach((item) => {
                if (optionsActions.auctions.length < res.recordsTotal) {
                    optionsActions.auctions.push(item);
                }
              });
              optionsActions.loading = false;
              if (optionsActions.auctions.length >= optionsActions.total) {
                optionsActions.finished = true;
              }
            } else {
                optionsActions.loading = false;
                optionsActions.finished = true;
            }
          });
        }
       
        //options.loading = false;
        //options.finished = true;
        console.log("loading:"+optionsActions.loading+" finished:"+optionsActions.finished)
      };
      onRefresh();
      return {
        optionsActions,
        onRefresh,
        onLoad,
        auctionTypeSearch,
      };
}

export default auctionSearchModel;