<template>
  <head-nav :isLink="true"></head-nav>
  <ad-img code="Index_Rotation" :height="28"></ad-img>
  <div class="clear"></div>
  <div class="typeNav">
    <ul>
      <!-- <li>
        <a href="shoplist.html">
          <img src="images/typeicon1.png" />
          <p>今日特惠</p>
        </a>
      </li>
      <li>
        <a href="shoplist.html">
          <img src="images/typeicon2.png" />
          <p>全球购</p>
        </a>
      </li> -->
      <li>
        <a href="javascript:;" @click="sign">
          <img src="images/typeicon5.png" />
          <p>签到</p>
        </a>
      </li>
      <li>
        <a href="javascript:;" @click="authSend">
          <img src="images/typeicon3.png" />
          <p>余额中心</p>
        </a>
      </li>
      <li>
        <router-link to="/myPoints">
          <img src="images/typeicon4.png" />
          <p>我的积分</p>
        </router-link>
      </li>
      <li>
        <router-link to="/goodsEdit/0">
          <img src="images/m_05.png" />
          <p>上传拍品</p>
        </router-link>
      </li>
      <li>
       <router-link to="/pointsGoodsList">
          <img src="images/typeicon6.png" />
          <p>积分兑换</p>
        </router-link>
      </li>
      <li>
        <router-link to="/snap">
          <img src="images/lxp.png" />
          <p>连续拍</p>
        </router-link>
      </li>
      <li>
        <router-link to="/order">
          <img src="images/typeicon7.png" />
          <p>我的订单</p>
        </router-link>
      </li>
      <li>
        <router-link to="/auctionGoodsType">
          <img src="images/typeicon8.png" />
          <p>分类</p>
        </router-link>
      </li>
     
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hotTit">
    <div class="hotTitL">
      <img src="images/hotit.png" />
    </div>
    <div class="hotTitR">
      <van-notice-bar  :scrollable="false" style="width:100%;">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="n in options.notice" :key="n.id">
            <router-link style="color:#ed6a0c;" :to="'/notice/'+n.id">{{n.title}}</router-link>
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="w100">
    <ad-img code="IndexAd" height="12"></ad-img>
  </div>

  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="timeBuy" v-if="options.defaultAuction.length > 0">
    <div class="Buybox">
      <p><span>最新</span>竞拍</p>
      <router-link to="/auctionList" class="more"></router-link>
      <router-link to="/auctionList" class="btn-more">更多</router-link>
    </div>
    <ul>
      <li v-for="a in options.defaultAuction"  :key="a.id">

        <router-link :to="'/auctionDetail/' + a.id">
        <div style="width:5rem;height: 5rem;">
          <van-image
                  width="4rem"
                  height="4rem"
                  style="margin-left: 1.1rem;object-fit: cover"
                  :src="a.pictureSmall"
                />
        </div>
          <p>{{ a.title }}</p>
        </router-link>
      </li>
    </ul>
    <div class="clear"></div>
  </div>
  <snap-top5></snap-top5>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="timeBuy" v-if="options.fixedAuction.length > 0">
    <div class="Buybox">
      <p><span>最新</span>一口价</p>
      <router-link to="/auctionList" class="more"></router-link>
      <router-link to="/auctionList" class="btn-more">更多</router-link>
    </div>
    <ul>
      <li v-for="a in options.fixedAuction" :key="a.id">
        <router-link :to="'/auctionDetail/' + a.id">
         <div style="width:5rem;height: 5rem;">
         <van-image
                  width="4rem"
                  height="4rem"
                  style="object-fit: cover"
                  :src="a.pictureSmall"
                />
         </div>
          <p>{{ a.title }}</p>
        </router-link>
      </li>
    </ul>
    <div class="clear"></div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="xqtab">
    <div class="Menubox">
      <van-sticky>
        <ul style="background-color: #fff">
          <li
            :class="optionsActions.style == 1 ? 'hover' : ''"
            @click="auctionTypeSearch('DEFAULT')"
          >
            竞拍
          </li>
          <li
            :class="optionsActions.style == 2 ? 'hover' : ''"
            @click="auctionTypeSearch('FIXED')"
          >
            一口价
          </li>
          <li
            :class="optionsActions.style == 3 ? 'hover' : ''"
            @click="auctionTypeSearch('SNAP')"
          >
            连续拍
          </li>
        </ul>
      </van-sticky>
    </div>

    <div class="Contentbox" >
      <div>
        <div class="likebox bort">
            <ul class="van-clearfix">
              <van-list
                v-model:loading="optionsActions.loading"
                :finished="optionsActions.finished"
                :offset="0"
                finished-text="没有更多了"
                loading-text="数据加载中......"
                :immediate-check="false"
                @load="onLoad"
              >
                <li v-for="a in optionsActions.auctions" :key="a.id">
                  <router-link
                    v-if="optionsActions.style == 3"
                    :to="'/snapInfo/' + a.id"
                  >
                    <van-image
                      width="10.2rem"
                      height="12rem"
                      class="proimg"
                      style="object-fit: cover"
                      :src="a.img"
                    />
                    <p class="tit">{{ a.title }}</p>
                    <p class="price">
                      <!-- ￥{{ s.amount }}<img src="images/f3.png" /> -->
                    </p>
                  </router-link>
                  <router-link v-else :to="'/auctionDetail/' + a.id">
                    <van-image
                      width="11rem"
                      height="12rem"
                      class="proimg"
                      style="object-fit: cover"
                      :src="a.pictureSmall"
                    />
                    <p class="tit">{{ a.title }}</p>
                    <p class="price">
                      <!-- <img src="images/f3.png" /> -->
                    </p>
                  </router-link>
                </li>
              </van-list>
            </ul>
        </div>
      </div>
    </div>
  </div>
  <van-sticky :offset-bottom="50" @scroll="topShow" position="bottom">
   <div class="totop" v-if="options.isTopShow">
    <a href="javascript:scrollTo(0,0)"><img src="images/totop.png" /></a>
  </div>
  </van-sticky>
  <div class="fbox"></div>
  <footer-nva></footer-nva>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterNva from "@/components/FooterNva.vue";
import { reactive, computed} from "vue";
import { useStore } from "vuex";
import auctionApi from "@/api/auctionApi";
import cmsApi from "@/api/cmsApi";
import AdImg from "@/components/AdImg.vue";
import SnapTop5 from "@/components/Index/SnapTop5.vue";
import auctionSearchModel from '@/model/index/auctionSearchModel'
import stringUtils from "@/utils/stringUtils";
import config from '@/config/configUtils'
import { Toast } from "vant";
export default {
  components: {
    HeadNav,
    FooterNva,
    AdImg,
    SnapTop5,
    //IndexCategory,
  },
  setup() {
    const options = reactive({
      imgs: [
        { url: "images/banner.jpg", title: "" },
        { url: "images/banner.jpg", title: "" },
      ],
      publishNew: [],
      startSoon: [],
      notice:[],
      categorys: [],
      fixedAuction: [],
      defaultAuction: [],
      isTopShow:false,
      all:[]
    });
    const store = useStore();
    let {optionsActions,onRefresh,onLoad,auctionTypeSearch,}=auctionSearchModel();
    options.categorys = computed(() => store.state.cms.categorys);
    const topShow=(val)=>{
      if(val.scrollTop>300){
          options.isTopShow=true;
      }else{
          options.isTopShow=false;
      }
      
    }
    const Notice=()=>{
      cmsApi.systemNotice().then(res=>{
         if(res.status==200){
            options.notice=res.result;
         }
      });
    }
    const sign=()=>{
      cmsApi.signSave().then(res=>{
         if(res.status==200){
            Toast.success('签到成功');
         }else{
          Toast.fail(res.message);
         }
      });
    }
    function defaultA() {
      auctionApi.default().then((res) => {
        if (res.status == 200) {
          options.defaultAuction = res.result;
        }
      });
    }
    function fixed() {
      auctionApi.fixed().then((res) => {
        if (res.status == 200) {
          options.fixedAuction = res.result;
        }
      });
    }
    const authSend=()=>{
      stringUtils.redirectForm(config.authUrl,[],'post');
    }
    options.all=computed(() => store.state.cms.ads)
    // nextTick(() => {
    //   initShareConfig({
    //     title:'乐粹艺拍',
    //     imgUrl:'http://img.lecuiyipai.com/advert/20240410/171271678266521b88b59-0198-496c-8622-42360b77056b.jpg',
    //     desc:'',
    //     link:decodeURI(window.location.href)
    //   });
    // });
    defaultA();
    fixed();
    Notice();
    return {
      options,
      topShow,
      sign,
      authSend,
      optionsActions,
      onRefresh,
      onLoad,
      auctionTypeSearch,
    };
  },
};
</script>
<style>
.btn-more {
  float: right;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.75rem;
}
.notice-swipe {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
  }
.van-notice-bar {
  width: 100%;
   height: 2.6rem;
}
</style>