<template>
<div class="timeBuy" v-if=" options.data.length>0">
    <div class="Buybox">
      <p><span>连续</span>抢拍</p>
      <router-link to="/snap" class="more"></router-link>
      <router-link to="/snap" class="btn-more">更多</router-link>
    </div>
     <ul>
      <li v-for=" a in options.data" :key="a.id">
        <router-link :to="'/snapInfo/'+a.id">
         <div style="width:5rem;height: 5rem;">
          <van-image
                  width="4rem"
                  height="4rem"
                  style="margin-left: 1.1rem; object-fit: cover"
                  :src="a.img"
                />
         </div>
          <p>{{a.title}}</p>
        </router-link>
      </li>
    </ul> 
    <div class="clear"></div>
  </div>
 
</template>
<script>
import snapApi from "@/api/snapApi";
import { reactive } from '@vue/reactivity';
export default {
    
    setup(){
        const options=reactive({
            data:[]
        })
        const get=()=>{
            snapApi.getTop5().then(res=>{
                if(res.status==200){
                    options.data=res.result;
                }
            });
        }
        get();
        return {
          options  
        }
    }
}
</script>

<style>

</style>